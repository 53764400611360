.adm-picker-view {
  --height: 240px;
  --item-height: 34px;
  --item-font-size: var(--adm-font-size-8);
  height: var(--height);
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
  background: var(--adm-color-background);
}
.adm-picker-view-column {
  height: 100%;
  flex: 1;
  -webkit-user-select: none;
          user-select: none;
  touch-action: none;
  position: relative;
  z-index: 0;
}
.adm-picker-view-column-wheel {
  width: 100%;
  cursor: -webkit-grab;
  cursor: grab;
  position: absolute;
  top: calc(50% - var(--item-height) / 2);
  left: 0;
}
.adm-picker-view-column-wheel::before {
  content: ' ';
  display: block;
  position: absolute;
  width: 100%;
  height: 100vh;
  top: -100vh;
}
.adm-picker-view-column-wheel::after {
  content: ' ';
  display: block;
  position: absolute;
  width: 100%;
  height: 100vh;
  bottom: -100vh;
}
.adm-picker-view-column-item {
  font-size: var(--item-font-size);
  padding: 0 6px;
  height: var(--item-height);
  display: flex;
  justify-content: center;
  align-items: center;
}
.adm-picker-view-column-item-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.adm-picker-view-column-accessible {
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 0;
  padding-bottom: 1px;
}
.adm-picker-view-column-accessible > * {
  flex: 1;
  text-overflow: ellipsis;
}
.adm-picker-view-column-accessible-current {
  position: absolute;
  width: 100%;
  height: 100%;
}
.adm-picker-view-column-accessible-button {
  width: 100%;
  height: 100%;
}
.adm-picker-view-mask {
  position: absolute;
  z-index: 10000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  pointer-events: none;
}
.adm-picker-view-mask-top,
.adm-picker-view-mask-bottom {
  flex: auto;
}
.adm-picker-view-mask-middle {
  height: var(--item-height);
  box-sizing: border-box;
  flex: none;
  border-top: solid 1px var(--adm-color-border);
  border-bottom: solid 1px var(--adm-color-border);
}
.adm-picker-view-mask-top {
  background: var(--adm-color-background);
  -webkit-mask: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.8) 50%, #000000 100%);
          mask: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.8) 50%, #000000 100%);
}
.adm-picker-view-mask-bottom {
  background: var(--adm-color-background);
  -webkit-mask: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.8) 50%, #000000 100%);
          mask: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.8) 50%, #000000 100%);
}
.adm-picker-view-loading-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
