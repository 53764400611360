.adm-image-uploader {
  --cell-size: 80px;
}
.adm-image-uploader-space {
  --gap: 12px;
}
.adm-image-uploader-cell {
  position: relative;
  width: var(--cell-size);
  height: var(--cell-size);
  border-radius: 4px;
  overflow: hidden;
}
.adm-image-uploader-cell-fail {
  border: red solid 1px;
  box-sizing: border-box;
}
.adm-image-uploader-cell-delete {
  position: absolute;
  top: 0;
  right: 0;
  width: 14px;
  height: 14px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 0 0 0 12px;
  font-size: 8px;
  color: var(--adm-color-white);
  cursor: pointer;
}
.adm-image-uploader-cell-delete-icon {
  position: absolute;
  left: 4px;
  top: 3px;
}
.adm-image-uploader-cell-mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  color: var(--adm-color-white);
  background-color: rgba(50, 50, 51, 0.88);
}
.adm-image-uploader-cell-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  box-sizing: border-box;
  padding-top: 8px;
}
.adm-image-uploader-cell-mask-message {
  display: inline-block;
  padding: 6px 4px;
  font-size: var(--adm-font-size-4);
}
.adm-image-uploader-cell-image {
  width: var(--cell-size);
  height: var(--cell-size);
}
.adm-image-uploader-upload-button-wrap {
  position: relative;
}
.adm-image-uploader-upload-button-wrap .adm-image-uploader-upload-button {
  background-color: var(--adm-color-box);
  text-align: center;
  line-height: var(--cell-size);
  display: block;
}
.adm-image-uploader-upload-button-wrap .adm-image-uploader-upload-button-icon {
  color: #999999;
  font-size: 32px;
}
.adm-image-uploader-upload-button-wrap .adm-image-uploader-input {
  cursor: pointer;
  position: absolute;
  opacity: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}
